<template>
  <b-dropdown id="dropdown-form" ref="dropdown" :variant="variant" class="w-100" no-caret>
    <template v-slot:button-content>
      <div class="d-flex justify-content-between align-items-center w-100">
        <template v-if="!isLoading">
          <span>{{ filial }}</span>
          R$ {{ total | money
          }}
          <b-badge class="ml-1" variant="light">{{ totalQtde }}</b-badge>
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner variant="secondary" label="Totalizando..." small></b-spinner>
          </div>
        </template>
      </div>
    </template>

    <b-dropdown-text class="d-flex justify-content-between align-items-center w-100">
      <b-row class="w-100 mt-2">
        <b-col class="text-center">
          <h4>Itens do Carrinho {{ filial }}</h4>
        </b-col>
      </b-row>
    </b-dropdown-text>

    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-text
      v-if="this.$store.state.carrinho.cartMga!=null"
      class="d-flex justify-content-between align-items-center w-100"
    >
      <b-row class="w-100">
        <b-col>
          <h6>CNPJ: {{ this.clientBasicInfo.cnpj }}</h6>
        </b-col>
      </b-row>
      <b-row class="w-100">
        <b-col>
          <h6>RAZÃO SOCIAL: {{ this.clientBasicInfo.razao_social }}</h6>
        </b-col>
      </b-row>
    </b-dropdown-text>
    <b-form class="pl-0 pr-0 ml-0 mr-0 carrinho-itens" @submit.stop.prevent>
      <b-table
        class="w-100"
        :items="this.carrinhoItens"
        :fields="fields"
        v-on:sort-changed="onSortChanged"
        striped
        small
        hover
        bordered
        :sort-by.sync="sortBy"
        :sort-direction.sync="sortDirection"
        :no-sort-reset="true"
        stacked="sm"
        ref="cartTable"
      >
        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
        <template v-slot:cell(id)="data">
          <b-btn v-on:click="deleteCartItem(data.item)" variant="none" size="lg">
            <i class="icons mr-1 cui-trash"></i>
          </b-btn>
        </template>
        <template v-slot:cell(valorTotal)="data">R$ {{data.value | money}}</template>
        <template v-slot:cell(quantidade)="data">
          <b-form-input
            :ref="'qtdeId'+data.item.id"
            :id="'qtdeId'+data.item.id"
            v-model="data.item.quantidade"
            :aria-describedby="'qtdeId'+data.item.id+'Feedback'"
            :type="'number'"
            required
            :readonly="qtyReadOnly"
            :state="checkQty(data.item.quantidade, data.item.multiplo)"
            :step="data.item.multiplo"
            :min="data.item.multiplo"
            v-on:change="onQtyChange($event, data.item)"
            v-on:focus="onQtyFocus"
            v-on:blur="onQtyBlur"
            class="qty-size text-center"
            :no-wheel="true"
          ></b-form-input>
          <b-form-invalid-feedback
            :id="'qtdeId'+data.item.id+'Feedback'"
          >A quantidade deve ser maior que zero e múltiplo de {{data.item.multiplo}}</b-form-invalid-feedback>
        </template>
      </b-table>
    </b-form>
    <b-dropdown-divider v-if="temItens"></b-dropdown-divider>

    <b-dropdown-text class="d-flex justify-content-between align-items-center w-100">
      <b-row class="w-100">
        <b-col md="6">
          <h6>
            TOTAL: R$ {{ getTotalCarrinho | money }}
          </h6>
        </b-col>
        <b-col md="6">
          <h6>
            QTDE. TOTAL: {{ getQuantidadeTotalCarrinho }}
          </h6>
        </b-col>
      </b-row>
    </b-dropdown-text>

    <b-dropdown-divider v-if="temItens"></b-dropdown-divider>
    <b-dropdown-item-button
      v-if="qtyChanged"
      v-on:click="saveQtyChanges"
      class="btn-primary text-center w-100"
    >
      <i class="icons mr-1 cui-disk text-white"></i>
      <span class="text-white">Salvar Alterações</span>
    </b-dropdown-item-button>
    <div class="btn-carrinho">
      <b-dropdown-item-button
        v-if="temItens"
        v-on:click="createOrcamento"
        class="btn-success text-center w-100"
      >
        <i class="icons mr-1 cui-list text-white"></i>
        <span class="text-white">Gerar Orçamento</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button
        v-on:click="deleteCart"
        v-if="temCarrinho"
        class="btn-danger text-center w-100"
      >
        <i class="icons mr-1 cui-trash text-white"></i>
        <span class="text-white">Excluir Carrinho</span>
      </b-dropdown-item-button>
    </div>
    
    <b-dropdown-text
      v-if="!temItens"
      class="mb-3"
    >Não há produtos no carrinho, clique em adicionar para para adicionar itens ao carrinho.</b-dropdown-text>
  </b-dropdown>
</template>

<style>
.money-no-wrap {
  white-space: nowrap !important;
}
.qty-current {
  background-color: #ffffcc !important;
  color: #000 !important;
  font-weight: bolder !important;
}
.carrinho-itens {
  height: 17rem;
  overflow: hidden auto;
}
.btn-carrinho {
  display: flex;
  flex-direction: row;
}

.btn-primary{
  background-color: #0075EB !important;
}

</style>

<style lang='scss' scoped>
@import "./../../../../node_modules/bootstrap/scss/_mixins.scss";
@import "./../../../../node_modules/bootstrap/scss/_functions.scss";
@import "./../../../../node_modules/bootstrap/scss/_variables.scss";
@import "./../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";
@include media-breakpoint-between(md, xl) {
  .carrinho-itens {
    width: 37vw;
  }
  .qty-size {
    width: 8rem;
  }
}
@include media-breakpoint-between(xs, sm) {
  .carrinho-itens {
    width: 90vw;
  }
}
</style>

<script>
import { mapState } from "vuex";
import Carrinho from "@/services/carrinho";

export default {
  name: "ProdutoCarrinhoBtn",
  props: {
    id: {
      type: String,
      default: "ProdutoCarrinhoBtn"
    },
    name: {
      type: String,
      default: "ProdutoCarrinhoBtn"
    },
    status: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    filial: {
      type: String,
      default: ""
    },
    variant: {
      type: String,
      default: ""
    }
  },
  created() {
    this.LoadCartItems();
  },
  mounted() {
    if (this.$store.state.carrinho.cartMga != null) {
      this.clientBasicInfo = this.$store.state.carrinho.cartMga.cliente;
    }
  },
  data() {
    return {
      clientBasicInfo: new Object(),
      qtyChanges: [],
      qtyReadOnly: false,
      sortBy: "produto_codigo",
      sortDirection: "asc",
      localLoading: false,
      carrinhoItens: [],
      total: 0.0,
      totalQtde: 0,
      fields: [
        {
          key: "produto_codigo",
          label: "Cód.",
          sortable: true,
          tdClass: "text-right"
        },
        {
          key: "quantidade",
          label: "Qtde.",
          sortable: true,
          tdClass: "text-right"
        },
        {
          key: "valorTotal",
          label: "Vlr.Tot.",
          formatter: (value, key, item) => {
            return (
              item.quantidade *
              (item.valor_unitario + item.valor_st + item.valor_ipi)
            );
          },
          sortable: true,
          sortByFormatted: true,
          tdClass: "text-right money-no-wrap"
        },
        { key: "titulo", label: "Produto", sortable: true },
        {
          key: "id",
          label: "",
          sortable: false,
          tdClass: "text-center"
        }
      ]
    };
  },
  methods: {
    onSortChanged(data) {
      if (data.sortBy == "quantidade" || data.sortBy == "valorTotal")
        this.qtyReadOnly = true;
      else this.qtyReadOnly = false;
    },
    onQtyFocus(event) {
      event.preventDefault();
      var refName = event.target.id;
      if (this.qtyReadOnly) {
        this.sortDirection = "asc";
        this.sortBy = "produto_codigo";
        this.$helper.showMsg(
          "Nào é possível alterar a quantidade quando ordenado por 'Quantidade' ou 'Valor', a lista será ordenada automaticamente por 'Código'.",
          "warning"
        );

        var self = this;
        setTimeout(function() {
          self.qtyReadOnly = false;
          self.$refs[refName].$el.focus();
          self.$refs[refName].$el.classList.add("qty-current");
        }, 250);
      } else {
        this.$refs[refName].$el.classList.add("qty-current");
      }
    },
    onQtyBlur(event) {
      event.preventDefault();
      var refName = event.target.id;
      this.$refs[refName].$el.classList.remove("qty-current");
    },
    onQtyChange(event, data) {
      let index = this.$store.state.carrinho.cartMga.items.findIndex(
        x => x.id === data.id
      );
      let item = this.$store.state.carrinho.cartMga.items[index];
      if (item.quantidade * 1 != data.quantidade * 1) {
        this.qtyChanges.push(data.id);
      } else {
        this.qtyChanges = this.qtyChanges.filter(function(value) {
          return value != data.id;
        });
      }
    },
    totals() {
      this.total = this.getTotalCarrinho;
      this.totalQtde = this.getQuantidadeTotalCarrinho;
      this.$emit("onTotal", {
        total: this.total,
        totalQtde: this.totalQtde
      });
    },
    async LoadCartItems() {
      if (this.$store.state.carrinho.cartMga != null) {
        this.localLoading = true;
        Carrinho.getCarrinhoItens(1)
          .then(response => {
            this.$store.commit(
              "carrinho/CURRENT_CART_ITEMS",
              response.data.data
            );
          })
          .catch(e => {
            if (
              e.response &&
              e.response.status &&
              e.response.status === 404 &&
              e.response.data
            ) {
              this.$store.commit("carrinho/CURRENT_CART_ITEMS", null);
            } else {
              this.$helper.showErrorResponse(e);
            }
          })
          .finally(() => {
            this.totals();
            this.localLoading = false;
          });
      }
    },
    createCart(cliente_codigo, data) {
      this.localLoading = true;
      Carrinho.createCarrinho(cliente_codigo, data.cd_filial, data.cd_empresa)
        .then(response => {
          this.addToCart(data, 1, 1);
          this.$store.commit("carrinho/CURRENT_CART", response.data.data);
        })
        .catch(e => {
          this.localLoading = false;
          this.$helper.showErrorResponse(e);
        });
    },
    deleteCart() {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja excluir o carrinho atual?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.localLoading = true;
            this.$emit("onStartDeletingCart");
            Carrinho.deleteCarrinho(1)
            .then(() => {
              if(this.$store.state.carrinhoitj.cartItj == null)
              {
                this.$store.commit("carrinhoitj/CURRENT_CART_ITJ", null);
              }
              this.$store.commit("carrinho/CURRENT_CART", null);
              
              this.$helper.showMsg(
                "Carrrinho excluído com sucesso.",
                "success"
              );
            })
            .catch(e => {
              this.$helper.showErrorResponse(e);
            })
            .finally(() => {
              this.localLoading = false;
              this.$emit("onEndDeletingCart");
            });
          }
        })
        .catch(err => {
          this.$helper.showErrorResponse(err);
        });
    },
    saveQtyChanges() {
      this.carrinhoItens.forEach(item => {
        if (this.qtyChanges.includes(item.id)) {
          this.localLoading = true;
          Carrinho.setCarrinhoItemQuantidade(item.id, item.quantidade)
            .then(() => {
              this.qtyChanges = this.qtyChanges.filter(function(value) {
                return value != item.id;
              });
              if (this.qtyChanges.length == 0) {
                this.LoadCartItems();
              } else {
                this.localLoading = false;
              }
            })
            .catch(e => {
              this.localLoading = false;
              this.$helper.showErrorResponse(e);
            });
        }
      });
    },
    deleteCartItem(item) {
      this.$bvModal
        .msgBoxConfirm(
          "Tem certeza que deseja excluir o produto " +
            item.produto_codigo +
            " - " +
            item.titulo +
            " do carrinho?",
          {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          if (value) {
            this.localLoading = true;
            Carrinho.deleteCarrinhoItem(item.id)
              .then(() => {
                this.LoadCartItems().finally(() => {
                  this.$helper.showMsg(
                    "Produto excluído com sucesso.",
                    "success"
                  );
                });
              })
              .catch(e => {
                this.localLoading = false;
                this.$helper.showErrorResponse(e);
              });
          }
        })
        .catch(err => {
          this.$helper.showErrorResponse(err);
        });
    },
    createOrcamento() {
      this.$bvModal
        .msgBoxConfirm(
          "Tem certeza que deseja converter o carrinho atual em um orçamento?",
          {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "danger",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          if (value) {
            this.localLoading = true;
            this.$emit("onStartCreateOrcamento");
            Carrinho.createOrcamento(1)
              .then(response => {
                this.$store.commit("carrinho/CURRENT_CART", null);
                this.$store.commit("carrinho/CURRENT_CLIENT", this.clientBasicInfo.codigo);
                this.$router.push(
                  "orcamentos/orcamento/" + response.data.data.id + "/editar"
                );
              })
              .catch(e => {
                this.$helper.showErrorResponse(e);
              })
              .finally(() => {
                this.localLoading = false;
                this.$emit("onEndCreateOrcamento");
              });
          }
        })
        .catch(err => {
          this.$helper.showErrorResponse(err);
        });
    },
    addToCart(data, filial, empresa) {
      this.localLoading = true;
      this.$emit("onStartAdding", data.codigo);
      Carrinho.addCarrinhoItem(data.codigo, data.quantidade, filial, empresa)
        .then(() => {
          this.LoadCartItems().finally(() => {
            this.$emit("onEndAdding", data.codigo);
          });
        })
        .catch(e => {
          this.$emit("onEndAdding", data.codigo);
          this.localLoading = false;
          this.$helper.showErrorResponse(e);
        });
    },
    checkQty(qty, multiplo) {
      return qty > 0 && qty % multiplo == 0;
    }
  },
  computed: {
    getTotalCarrinho() {
      if (this.carrinhoItens == null || this.carrinhoItens.length == 0)
        return 0.0;

      return this.carrinhoItens.reduce(function(total, item) {
        return (
          total +
          item.quantidade *
            (item.valor_unitario + item.valor_st + item.valor_ipi)
        );
      }, 0);
    },
    getQuantidadeTotalCarrinho() {
      if (this.carrinhoItens == null || this.carrinhoItens.length == 0)
        return 0.0;

      return this.carrinhoItens.reduce(function(total, item) {
        return total + item.quantidade * 1;
      }, 0);
    },
    temCarrinho() {
      return this.$store.state.carrinho.cartMga != null;
    },
    isLoading() {
      return this.localLoading || this.loading;
    },
    temItens() {
      return this.carrinhoItens && this.carrinhoItens.length > 0;
    },
    qtyChanged() {
      return this.qtyChanges && this.qtyChanges.length > 0;
    },
    ...mapState("carrinho", {
      lastChange: "lastChange"
    })
  },
  watch: {
    lastChange() {
      if (
        this.$store.state.carrinho.cartMga != null &&
        this.$store.state.carrinho.cartMga.items
      ) {
        this.clientBasicInfo = this.$store.state.carrinho.cartMga.cliente;
        this.carrinhoItens = JSON.parse(
          JSON.stringify(this.$store.state.carrinho.cartMga.items)
        );
      } else {
        this.carrinhoItens = [];
      }
      this.totals();
    }
  }
};
</script>
