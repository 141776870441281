<template>
  <div class="animated fadeIn">
    <b-row v-show="exibir">
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <i class="icon-list"></i>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="3">
                <b-form-group label="CNPJ*" label-for="cnpj">
                  <b-input-group>
                    <b-form-input
                      id="cnpj"
                      type="text"
                      :value="cnpj"
                      placeholder="CNPJ"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group label="Razão Social" label-for="razao_social">
                  <b-form-input
                    id="razao_social"
                    type="text"
                    :value="razao"
                    placeholder="Razão Social"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="mt-4 pt-1 pl-0 pr-0" id="remover-filtro">
                <b-button class="btn btn-block btn-ghost-danger" @click="deleteCarrinho"> 
                  <i class="fa fa-times"></i>
                  Limpar filtro de cliente
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card no-body>
      <b-card-header>
        <span>
          <i class="icon-list"></i> Lista de Produtos
        </span>
      </b-card-header>
      <b-card-body>
        <product-list
          id="produtosList"
          ref="produtosList"
          :cliente_codigo="cliente_codigo"
          :resultsPerPage="10"
          v-on:onAddMga="onAddMga"
          v-on:onAddItj="onAddItj"
          v-on:onAdd="onAdd"
        >
          <template #segundaColuna>
            <produto-carrinho-btn
              :loading="IsCartChanging"
              v-on:onTotal="onTotal"
              filial="MGA"
              variant="primary"
              v-on:onStartDeletingCart="onStartDeletingCart"
              v-on:onEndDeletingCart="onEndDeletingCart"
              v-on:onStartAdding="onStartAdding"
              v-on:onEndAdding="onEndAdding"
              v-on:onStartCreateOrcamento="onStartCreateOrcamento"
              v-on:onEndCreateOrcamento="onEndCreateOrcamento"
              ref="carrinhoBtn"
              :disabled="IsCartChanging"
            ></produto-carrinho-btn>
          </template>
          
          <template #terceiraColuna>
            <produto-carrinho-btn-itj
              :loading="IsCartChangingItj"
              filial="ITJ"
              variant="warning"
              v-on:onTotalItj="onTotalItj"
              v-on:onStartDeletingCartItj="onStartDeletingCartItj"
              v-on:onEndDeletingCartItj="onEndDeletingCartItj"
              v-on:onStartAddingItj="onStartAddingItj"
              v-on:onEndAddingItj="onEndAddingItj"
              v-on:onStartCreateOrcamentoItj="onStartCreateOrcamentoItj"
              v-on:onEndCreateOrcamentoItj="onEndCreateOrcamentoItj"
              ref="carrinhoBtnItj"
              :disabled="IsCartChangingItj"
            ></produto-carrinho-btn-itj>
          </template>
          
        </product-list>
      </b-card-body>
    </b-card>
    <cliente-escolha
      id="clienteEscolhaId"
      title="Escolha um cliente para criar o carrinho"
      v-on:onClienteSelecionado="onClienteSelecionado"
      :data="data"
    ></cliente-escolha>
  </div>
</template>

<style>
#cnpjclienteEscolhaId{
  width: 20%;
}
#statusclienteEscolhaId{
  width: 10%;
}
#contatoclienteEscolhaId{
  width: 15%;
}
#razao_socialclienteEscolhaId{
  width: 55%;
}
</style>

<script>
import { mapState } from "vuex";
import ProductList from "./ProductList";
import ProdutoCarrinhoBtn from "./ProdutoCarrinhoBtn";
import ProdutoCarrinhoBtnItj from "./ProdutoCarrinhoBtnItj";
import ClienteEscolha from "../clientes/ClienteEscolha";
import Produto from "@/services/produto";
import Carrinho from "@/services/carrinho";
import Clientes from "../clientes/Clientes.vue";

export default {
  name: "Produtos",
  components: { ProductList, ProdutoCarrinhoBtn, ClienteEscolha, Carrinho, Clientes,ProdutoCarrinhoBtnItj },
  computed: {
    ...mapState("carrinho", {
      cliente_codigo: "cliente_codigo",
    }),
    ...mapState("carrinhoitj", {
      cliente_codigo: "cliente_codigo",
    }),
    IsCartChanging() {
      return this.cartIsChanging;
    },
    IsCartChangingItj(){
      return this.cartIsChangingItj;
    }
  },
  mounted(){
    this.limpaCarrinhos();
  },
  data() {
    return {
      adding: [],
      addingItj: [],
      cartIsChanging: false,
      cartIsChangingItj: false,
      data: null,
      total: 0,
      totalQtde: 0,
      exibir: false,
      cnpj: 0,
      razao: ''
    };
  },
  methods: {
    limpaCarrinhos(){
      Carrinho.deleteCarrinho(1);
      Carrinho.deleteCarrinho(2);
      Carrinho.deleteCarrinhoItem(1);
      Carrinho.deleteCarrinhoItem(2);

      if(this.$store.state.carrinhoitj.cliente_codigo != null){
        this.$store.commit("carrinhoitj/CURRENT_CLIENT_ITJ", null);
      }

      if(this.$store.state.carrinho.cliente_codigo != null){
        this.$store.commit("carrinho/CURRENT_CLIENT", null);
      }

      if(this.$store.state.carrinhoitj.cartItj != null){
        this.$store.commit("carrinhoitj/CURRENT_CART_ITJ", null);
      }

      if(this.$store.state.carrinho.cartMga != null){
        this.$store.commit("carrinho/CURRENT_CART", null);
      }
    },
    onStartAdding(produto_codigo) {
      this.adding.push(produto_codigo);
    },
    onEndAdding(produto_codigo) {
      this.adding = this.adding.filter(function(value) {
        return value != produto_codigo;
      });
    },
    onStartCreateOrcamento() {
      this.cartIsChanging = true;
    },
    onEndCreateOrcamento() {
      this.cartIsChanging = false;
    },
    onStartDeletingCart() {
      this.exibir = false;
      this.cartIsChanging = true;
    },
    onEndDeletingCart() {
      this.cartIsChanging = false;
    },
    onTotal(data) {
      this.total = data.total;
      this.totalQtde = data.totalQtde;
      this.cartIsChanging = false;
    },
    onStartAddingItj(produto_codigo) {
      this.addingItj.push(produto_codigo);
    },
    onEndAddingItj(produto_codigo) {
      this.addingItj = this.addingItj.filter(function(value) {
        return value != produto_codigo;
      });
    },
    onStartCreateOrcamentoItj() {
      this.cartIsChangingItj = true;
    },
    onEndCreateOrcamentoItj() {
      this.cartIsChangingItj = false;
    },
    onStartDeletingCartItj() {
      this.exibir = false;
      this.cartIsChangingItj = true;
    },
    onEndDeletingCartItj() {
      this.cartIsChangingItj = false;
    },
    onTotalItj(data) {
      this.totalItj = data.total;
      this.totalQtdeItj = data.totalQtde;
      this.cartIsChangingItj = false;
    },
    onAddMga(data)
    {
      if (this.$store.state.carrinho.cartMga == null) 
      {
       this.openCart(data.codigo, this.$store.state.carrinho.cliente_codigo, 1, data);
      }else
      {
        this.$refs.carrinhoBtn.addToCart(data, 1, 1);
      }
    },
    onAddItj(data)
    {
      if (this.$store.state.carrinhoitj.cartItj == null) 
      {
        this.openCart(data.codigo, this.$store.state.carrinhoitj.cliente_codigo, 2, data);
      }else
      {
        this.$refs.carrinhoBtnItj.addToCart(data, 2, 1);
      }
    },
    onAdd(data) {
      if(!data){
        this.ChooseClient(data);
      }
    },
    ChooseClient() {
      this.$bvModal.show("clienteEscolhaId");
    },
    onClienteSelecionado(cliente) {
      if(!this.data){
        /*Quando o cliente não estiver selecioando vai cair aqui */
        this.exibir = true;
        this.$store.commit("carrinhoitj/CURRENT_CLIENT_ITJ", cliente.codigo);
        this.$store.commit("carrinho/CURRENT_CLIENT", cliente.codigo);
        this.cnpj = cliente.cnpj;
        this.razao = cliente.razao_social;
      } else {
        this.openCart(this.data.codigo, cliente.codigo, data.filial);
      }
    },
    openCart(codigoProduto, codigoCliente, filial, data){
      codigoCliente = this.$store.state.carrinho.cliente_codigo != null ? this.$store.state.carrinho.cliente_codigo : this.$store.state.carrinhoitj.cliente_codigo;
      Produto.getValoresCliente(codigoProduto, codigoCliente)
          .then(response => {
            if(response.data.data.valor_unitario > 0 || response.data.data[1].valor_unitario > 0)
            {
              data.cd_filial = filial;
              console.log(data);
              data.cd_empresa = 1;
              if(filial == 2)
              {
                this.$refs.carrinhoBtnItj.createCart(codigoCliente, data);
              }else
              {
                this.$refs.carrinhoBtn.createCart(codigoCliente, data);  
              }
            } else {
              this.$helper.showMsg(
                "O produto solicitado não está disponível.",
                "warning"
              );
            }
          })
          .catch(e => {
            this.$helper.showMsg(
              "Não foi possível inserir o produto solicitado.",
              "warning"
            );
          })
    },
    createOrcamento() {
      this.$refs.carrinhoBtn.createOrcamento();
    },
    deleteCarrinho(){
      this.$refs.carrinhoBtn.deleteCart();
      this.$store.commit("carrinho/CURRENT_CLIENT", null);
      this.$store.commit("carrinhoitj/CURRENT_CLIENT_ITJ", null);
      this.$store.commit("carrinho/CURRENT_CART", null);
      this.$store.commit("carrinhoitj/CURRENT_CART_ITJ", null);

      this.cnpj = null;
      this.razao = null;
      this.exibir = false;
    },
  }
};
</script>