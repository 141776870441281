import { httpNoLoading } from './configNoLoading'

export default {
    getCarrinho: () => {
        return httpNoLoading.get('carrinho')
    },
    createCarrinho: (cliente_codigo, filial, empresa) => {
        let data = {
            "cliente_codigo": cliente_codigo,
            "cd_filial": filial,
            "cd_empresa": empresa
        };
        return httpNoLoading.post('carrinho', data);
    },
    deleteCarrinho: (filial) => {
        return httpNoLoading.delete('carrinho/' + filial + '/delete');
    },
    createOrcamento: (filial) => {
        return httpNoLoading.post('carrinho/criar-orcamento/' + filial);
    },
    addCarrinhoItem: (produto_codigo, quantidade, cd_filial, cd_empresa) => {
        let data = {
            "produto_codigo": produto_codigo,
            "cd_filial": cd_filial,
            "cd_empresa": cd_empresa,
            "quantidade": quantidade
        };
        return httpNoLoading.post('carrinho/item', data);
    },
    getCarrinhoItem: (id) => {
        return httpNoLoading.get('carrinho/item/' + id);
    },
    getCarrinhoItens: (filial) => {
        return httpNoLoading.get('carrinho/' + filial + '/itens');
    },
    setCarrinhoItemQuantidade: (id, quantidade) => {
        let data = {
            "quantidade": quantidade
        };
        return httpNoLoading.put('carrinho/item/' + id, data);
    },
    deleteCarrinhoItem: (id) => {
        return httpNoLoading.delete('carrinho/item/' + id);
    },
}