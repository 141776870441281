<template>
  <b-modal
    @show="chooseClientShow"
    size="lg"
    :id="id"
    ref="chooseClientModal"
    @ok="handleOk"
    :title="title"
  >
    <template v-slot:default>
      <b-row v-if="loadingClients" class="text-center">
        <b-col>
          <b-spinner variant="secondary" class="mt-3 mb-3" label="Buscando clientes..."></b-spinner>
        </b-col>
      </b-row>
      <my-data-table
        v-else
        id="clienteEscolhaId"
        :search="searchResult.search"
        :fields="searchResult.fields"
        :hiddenFields="hiddenFields"
        :sortColumn="searchResult.sortColumn"
        :sortDirection="searchResult.sortDirection"
        :totalResults="searchResult.totalResults"
        :results="searchResult.results"
        :resultsPerPage="searchResult.resultsPerPage"
        :showResultsPerPage="false"
        :totalPages="searchResult.totalPages"
        :page="searchResult.page"
        :pages="searchResult.pages"
        v-on:doSearch="loadClients"
        v-on:onSelectedRow="onSelectedRow"
        v-on:clienteSelecionado="handleOk"
        selectedRowClass="selectedRow"
      >
        <template v-slot:default="value">{{ value.propertyValue | format(value.propertyName) }}</template>
        <template v-slot:status="value">
          <cliente-status :status="value.propertyValue" />
        </template>
      </my-data-table>
    </template>

    <template v-slot:modal-footer="{ ok, cancel, hide }">
      <b-button size="sm" variant="danger" @click="cancel()">Cancelar</b-button>
      <b-button size="sm" variant="success" @click="ok()">Selecionar</b-button>
    </template>
  </b-modal>
</template>

<style>
.selectedRow {
  background-color: dodgerblue !important;
  color: white !important;
}
</style>

<script>
import Cliente from "../../../services/cliente";
import MyDataTable from "@/components/ui/MyDataTable";
import ClienteStatus from "./ClienteStatus";
import ClienteEscolha from "../clientes/ClienteEscolha";
import Vue from "vue";

export default {
  name: "ClienteEscolha",
  components: { MyDataTable, ClienteStatus, ClienteEscolha },
  props: {
    id: {
      type: String,
      default: "ClienteEscolha"
    },
    name: {
      type: String,
      default: "ClienteEscolha"
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cliente: null,
      loadingClients: false,
      hiddenFields: ["codigo"],
      searchResult: function() {
        let searchResult = new Object();
        searchResult.resultsPerPage = 12;
        return searchResult;
      }
    };
  },
  computed: {},
  methods: {
    onSelectedRow(row) {
      this.cliente = row;
    },
    handleOk(event) {
      if (this.cliente == null) {
        event.preventDefault();
        this.$helper.showMsg("Por favor escolha um cliente.", "warning");
      } else {
        this.$refs.chooseClientModal.hide();
        this.$emit("onClienteSelecionado", this.cliente);
      }
    },
    loadClients(data = null) {
      this.loadingClients = true;
      this.cliente = null;
      Cliente.getListaClientesCarrinho(data)
        .then(response => {
          this.searchResult = response.data.data;
          this.searchResult.resultsPerPage = 12;
        })
        .catch(e => {
          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.searchResult = e.response.data.data;
            this.searchResult.resultsPerPage = 12;
          } else {
            this.$helper.showErrorResponse(e);
          }
        })
        .finally(() => {
          this.loadingClients = false;
        });
    },
    chooseClientShow() {
      let data = {
        params: {
          resultsPerPage: 12
        }
      };
      this.loadClients(data);
    },
    onClienteSelecionado() {
      //let retorno = this.$$refs.linha.onClienteSelecionado();
      console.log("Cliente chegou: "+retorno);
    }
  },
  filters: {
    format(value, field) {
      switch (field) {
        case "cnpj":
          return Vue.filter("cnpj")(value);
        case "razao_social":
          return Vue.filter("toUpper")(value);
        default:
          return value;
      }
    }
  }
};
</script>
